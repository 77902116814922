/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background: #F38E2C; }
.maintenance{text-align: center;margin: 70px auto;padding:20px;color: #ffffff;background: #58595b;}
.maintenance h1{font-size: 40px;margin: 32px auto;}
.maintenance h3{font-size: 32px;}
.cdk-overlay-pane, .mat-dialog-container{ background: #F38E2C; }
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {background-color: rgb(249 165 73);}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {background-color: #f58f21;}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {color: #f38e2c;}
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {background: #f58f21;}
.mat-calendar-body-selected{background: #F38E2C;}
.mat-datepicker-toggle-active, .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{color: #F38E2C;}
.mat-calendar-body-in-range::before {background: rgb(255 217 162);}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled), .mat-stroked-button.mat-primary, .mat-datepicker-toggle-active, .mat-form-field.mat-focused .mat-form-field-label, .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after, .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick, .mat-icon.mat-primary, .mat-input-element, .mat-form-field.mat-focused.mat-primary .mat-select-arrow {color: #F38E2C}
.mat-primary .mat-pseudo-checkbox-indeterminate, .mat-badge-content, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-toolbar.mat-primary {background: #F38E2C;}
.mat-stroked-button.mat-primary .mat-button-focus-overlay, .mat-mini-fab.mat-primary, .mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary, .mat-calendar-body-selected, .mat-form-field.mat-focused .mat-form-field-ripple, .mat-progress-bar-fill::after, .mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple, .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb, .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element, .mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb, .mat-primary .mat-slider-thumb-label, .mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit, .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar, .mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination,
.mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination{background-color: #F38E2C;}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {border-color: #F38E2C}
.mat-progress-spinner circle, .mat-spinner circle {stroke: #F38E2C}
.successSnackbar{background-color: #0e6f36;color: #ffffff;}
.alertSnackbar{background-color: #BE0010;color: #ffffff;}
